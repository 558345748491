


































import { defineComponent } from '@vue/composition-api';

import { PropsGroupLinkMenu, useGroupLinkMenu } from './GroupLinkMenuComposable';

type Props = PropsGroupLinkMenu;

export default defineComponent({
  name: 'BaseGroupLinkMenu',
  components: {},
  props: {
    groupId: { type: String, required: true },
    routeName: { type: String, required: true },
    adminMode: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useGroupLinkMenu(props);
  },
});
