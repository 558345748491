





















import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BasePins from '@/base/app/components/molecules/BasePins.vue';

import QuestionnaireBar from '../molecules/QuestionnaireBar.vue';
import { PropsQuestionnaires, useQuestionnaires } from './QuestionnairesComposable';

type Props = PropsQuestionnaires;

export default defineComponent({
  name: 'TrainingQuestionnaires',
  components: { BasePins, RefreshButton, QuestionnaireBar },
  props: {
    groupId: { type: String, required: true },
  },
  setup(props: Props) {
    return useQuestionnaires(props);
  },
});
