import { computed } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import { LocalDateTime } from '@/base/types';

export type PropsCourseFlexCard = {
  id: string;
  name: string;
  isLearning?: boolean;
  isExamOpen?: boolean;
  isExamScheduled?: boolean;
  contentsCount?: number;
  completedContentsCount?: number;
  reviewProblemCount?: number;
  courseColor?: string;
  courseImage?: string;
  groupId: string;
  groupName?: string;
  lastLearnedAt?: LocalDateTime;
  chipXSmall: boolean;
  returnQuery?: Record<string, undefined>;
  width?: number | string;
};

export function useCourseFlexCard(props: PropsCourseFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.courseFlexCard' });

  const progress = computed(() =>
    props.contentsCount !== undefined &&
    props.completedContentsCount !== undefined &&
    props.contentsCount > 0
      ? Math.round((props.completedContentsCount / props.contentsCount) * 1000) / 10
      : 0
  );

  const statuses = computed(() => {
    const keys: { key: string; icon?: string; to?: Location }[] = [];
    if (props.isLearning) keys.push({ key: 'in_progress' });
    else if (props.completedContentsCount === props.contentsCount) keys.push({ key: 'completed' });
    else keys.push({ key: 'not_begun' });
    if (props.isExamOpen) keys.push({ key: 'exam_in_progress' });
    if (props.isExamScheduled) keys.push({ key: 'exam_scheduled' });
    if (props.reviewProblemCount)
      keys.push({
        key: 'reviewLater',
        icon: 'mdi-play',
        to: {
          name: 'groupCourseReview',
          params: { id: props.groupId, courseId: props.id },
          query: props.returnQuery,
        },
      });
    return keys.map((v) => ({ ...v, label: msgs.of(v.key).value }));
  });

  const propsChip = computed(() => {
    const w =
      props.width !== '100%'
        ? `calc(((${props.width} - 20px) / ${statuses.value.length}) - 4px)`
        : undefined;
    return {
      small: !props.chipXSmall,
      xSmall: props.chipXSmall,
      maxWidth: props.chipXSmall ? w : undefined,
    };
  });

  return {
    progress,
    statuses,
    propsChip,
  };
}
