


























import { defineComponent } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';

export default defineComponent({
  name: 'HomeRecommendCourseFlexCard',
  components: { CourseBackground, BaseLink },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    contentsCount: { type: Number, default: undefined },
    completedContentsCount: { type: Number, default: undefined },
    isLearning: { type: Boolean, default: undefined },
    isExamOpen: { type: Boolean, default: undefined },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    returnQuery: { type: Object, default: undefined },
  },
  setup() {
    return {};
  },
});
