








































































import { defineComponent, PropType } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';
import { GlobalStoreLimitationName } from '@/base/app/store/types';
import { GroupRole, Role } from '@/base/domains';

import {
  GroupFlexCardCounts,
  PropsGroupFlexCard,
  useGroupFlexCard,
} from './GroupFlexCardComposable';

type Props = PropsGroupFlexCard;

export default defineComponent({
  name: 'HomeGroupFlexCard',
  components: { BaseLink, HintIcon },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    description: { type: String, default: undefined },
    groupRole: { type: String as PropType<GroupRole>, default: undefined },
    adminMode: { type: Boolean, default: false },
    role: { type: String as PropType<Role>, default: 'general' },
    menusAvailable: { type: Array as PropType<GlobalStoreLimitationName[]>, default: () => [] },
    counts: { type: Object as PropType<GroupFlexCardCounts>, default: undefined },
    iconSize: { type: [Number, String], default: 70 },
    buttonsLimit: { type: Number, default: 6 },
  },
  setup(props: Props) {
    return useGroupFlexCard(props);
  },
});
