import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { createUserAvatar } from '@/base/app/components/atoms/UserComposable';
import { useGlobalStore } from '@/base/app/store';
import { isFailed } from '@/base/usecases';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useGetQuestionnairesByAnswerer } from '../../../usecases';
import { PropsQuestionnaireBar } from '../molecules/QuestionnaireBarComposable';

export type PropsQuestionnaires = {
  groupId: string;
};

export function useQuestionnaires(props: PropsQuestionnaires) {
  const msgs = useMessages({ prefix: 'training.organisms.questionnaires' });
  const route = useRoute();
  const router = useRouter();
  const { findUser } = useGlobalStore();

  const loading = ref(false);
  const questionnaires = ref<PropsQuestionnaireBar[]>([]);
  const condition = ref<string[]>([]);

  const getQuestionnaires = useGetQuestionnairesByAnswerer();
  async function fetch() {
    loading.value = true;
    const res = await getQuestionnaires.execute({ groupId: props.groupId });
    loading.value = false;
    if (isFailed(res)) {
      questionnaires.value = [];
      return;
    }
    questionnaires.value = res.questionnaires.map((item) => ({
      id: item.id,
      title: item.title,
      by: createUserAvatar(item.createdBy, findUser),
      at: item.createdAt,
      groupId: item.groupId,
      createdAt: item.createdAt,
      unanswered: !item.answered,
    }));
  }

  const filteredQuestionnaires = computed(() =>
    questionnaires.value.filter((item) => {
      if (condition.value.includes('unanswered') && item.unanswered) return true;
      if (condition.value.includes('answered') && !item.unanswered) return true;
      return false;
    })
  );

  async function init() {
    condition.value = msgs.listOf('status').value.map((item) => item.value);
    fetch();
  }
  onMounted(init);
  watch(() => props.groupId, fetch);
  watch(
    () => route.query,
    (newVal) => {
      if (!newVal) return;
      if ('f' in newVal) {
        fetch();
        router.replace({ name: 'groupQuestionnaires', params: { id: props.groupId } });
      }
    }
  );

  return {
    loading,
    condition,
    questionnaires: filteredQuestionnaires,
    noDataText: msgs.of('noData'),
    statusList: msgs.listOf('status'),
    refresh: fetch,
  };
}
