










import { defineComponent } from '@vue/composition-api';

import User from '@/base/app/components/atoms/User.vue';
import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import { PropsUsers, useUsers } from './UsersComposable';

type Props = PropsUsers;

export default defineComponent({
  name: 'HomeUsers',
  components: { BaseTable, User },
  props: { groupId: { type: String, required: true } },
  setup(props: Props) {
    return useUsers(props);
  },
});
