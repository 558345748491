import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { PrivacyPolicyVersion, TermsOfServiceId } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { config } from '@/config';
import { useConfirmTermsOfService, useGetTermsServiceLatestVersions } from '@/home/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute } from '@/utils/VueUtils';

export function useConfirmTermsOfServiceDialog() {
  const msgs = useMessages({ prefix: 'home.organisms.confirmTermsOfServiceDialog' });
  const route = useRoute();
  const { user, tenant } = useGlobalStore();

  const showTermsOfService = ref(false);
  const showPrivacyPolicy = ref(false);
  const showTenantOwnTermsOfService = ref(false);

  const termsOfServiceVersion = ref<TermsOfServiceId>();
  const privacyPolicyVersion = ref<PrivacyPolicyVersion>();
  const tenantOwnTermsOfServiceVersion = ref<number>();

  const dialog = ref(false);
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
  }

  function open() {
    dialog.value = true;
  }

  const getTermsServiceLatestVersions = useGetTermsServiceLatestVersions();
  async function fetch() {
    assertIsDefined(user.value);
    const res = await getTermsServiceLatestVersions.execute({});
    if (isFailed(res)) return;
    if (
      user.value.role === 'supervisor' &&
      res.termsOfServiceVersion !==
        user.value.confirmedTermsOfServiceVersions?.termsOfServiceVersion
    ) {
      showTermsOfService.value = true;
      termsOfServiceVersion.value = res.termsOfServiceVersion;
    }
    if (
      res.privacyPolicyVersion !== user.value.confirmedTermsOfServiceVersions?.privacyPolicyVersion
    ) {
      showPrivacyPolicy.value = true;
      privacyPolicyVersion.value = res.privacyPolicyVersion;
    }
    if (
      tenant.value?.limitations.tenantOwnTermsOfService === 'enabled' &&
      user.value.role !== 'supervisor' &&
      res.tenantOwnTermsOfServiceVersion &&
      res.tenantOwnTermsOfServiceVersion !==
        user.value.confirmedTermsOfServiceVersions?.tenantOwnTermsOfServiceVersion
    ) {
      showTenantOwnTermsOfService.value = true;
      tenantOwnTermsOfServiceVersion.value = res.tenantOwnTermsOfServiceVersion;
    }
    if (showTermsOfService.value || showPrivacyPolicy.value || showTenantOwnTermsOfService.value) {
      open();
    }
  }
  onMounted(fetch);

  const confirmTermsOfService = useConfirmTermsOfService();
  async function confirm() {
    errors.value = undefined;
    loading.value = true;
    const res = await confirmTermsOfService.execute({
      termsOfServiceVersion: termsOfServiceVersion.value,
      privacyPolicyVersion: privacyPolicyVersion.value,
      tenantOwnTermsOfServiceVersion: tenantOwnTermsOfServiceVersion.value,
    });
    loading.value = false;
    if (isFailed(res)) {
      errors.value = res.errors;
      return;
    }
    close();
  }

  const toTermsOfService = computed(() => ({
    name: 'termsOfService',
    query: { ...toQuery(route) },
  }));

  const toTenantOwnTermsOfService = computed(() => ({
    name: 'tenantOwnTermsOfService',
    query: { ...toQuery(route) },
  }));

  const toPrivacyPolicy = config().app.privacyPolicyUrl;

  return {
    dialog,
    loading,
    errors,
    showTermsOfService,
    showPrivacyPolicy,
    showTenantOwnTermsOfService,
    toTermsOfService,
    toTenantOwnTermsOfService,
    toPrivacyPolicy,
    labelTermsOfService: msgs.of('termsOfService'),
    labelTenantOwnTermsOfService: msgs.of('tenantOwnTermsOfService'),
    labelPrivacyPolicy: msgs.of('privacyPolicy'),
    labelMessage: msgs.of('message').value,
    labelAgree: msgs.of('agree'),
    confirm,
    close,
    open,
  };
}

export type ConfirmTermsOfServiceDialog = ReturnType<typeof useConfirmTermsOfServiceDialog>;
