



















import { defineComponent } from '@vue/composition-api';

import GroupFlexCard from '../molecules/GroupFlexCard.vue';
import ConfirmTermsOfServiceDialog from './ConfirmTermsOfServiceDialog.vue';
import { useTenantForAdmin } from './TenantForAdminComposable';

export default defineComponent({
  name: 'HomeTenantForAdmin',
  components: { GroupFlexCard, ConfirmTermsOfServiceDialog },
  setup() {
    return useTenantForAdmin();
  },
});
