import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useScreen } from '@/base/app/utils/DomUtils';
import { Schedule } from '@/base/domains';

export type ScheduleFlexCardOpenPayload = {
  id: string;
};

export type PropsScheduleFlexCard = Schedule & {
  groupName?: string;
};

export function useScheduleFlexCard(
  props: PropsScheduleFlexCard,
  emit: (name: string, args: ScheduleFlexCardOpenPayload) => void
) {
  const month = computed(() => props.start.format('MMM'));
  const day = computed(() => props.start.format('D'));
  const w = computed(() => props.start.format('[(]ddd[)]'));
  const startTime = computed(() => props.start.format('HH:mm'));
  const endTime = computed(() => props.end?.format('HH:mm'));

  function open() {
    emit('open', { id: props.id });
  }

  const msgs = useMessages({ prefix: 'home.molecules.scheduleFlexCard' });
  return {
    month,
    day,
    w,
    startTime,
    endTime,
    labelOpen: msgs.of('detail'),
    open,
    ...useScreen(),
  };
}
