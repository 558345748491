import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';
import { HomeContent } from '@/home/usecases';

export type PropsContentFlexCard = HomeContent & {
  groupId: string;
  groupName: string;
  groupRole?: GroupRole;
  adminMode: boolean;
  returnQuery?: Record<string, undefined>;
};

export function useContentFlexCard(props: PropsContentFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.contentFlexCard' });

  const labelType = computed(() => msgs.of(props.type).value);
  const labelRequiredTime = computed(
    () => msgs.of('requiredTime', { min: props.requiredTime ?? 0 }).value
  );
  const labelStatus = computed(() => msgs.of(props.learningStatus).value);

  const displayToGroupContent = computed(() => {
    if (props.adminMode) return true;
    if (props.open && props.groupRole) return true;
    if (props.groupRole) return props.groupRole !== 'trainee';
    return false;
  });
  const toGroupContent = computed(() => ({
    name: 'groupContent',
    params: { id: props.groupId, courseId: props.courseId, contentId: props.id },
    query: props.returnQuery,
  }));

  const displayToGroupExams = computed(() => {
    if (props.type !== 'exam') return false;
    if (props.adminMode) return true;
    if (props.groupRole) return props.groupRole !== 'trainee';
    return false;
  });
  const toGroupExams = computed(() => {
    const query = { courseId: props.courseId, contentId: props.id };
    if (props.groupRole === 'trainer') Object.assign(query, { edit: '' });
    if (props.returnQuery) Object.assign(query, props.returnQuery);
    return { name: 'trainerGroupExams', params: { id: props.groupId }, query };
  });

  return {
    displayToGroupContent,
    toGroupContent,
    displayToGroupExams,
    toGroupExams,
    labelType,
    labelRequiredTime,
    labelStatus,
    labelStart: msgs.of('start'),
    labelOpen: msgs.of('open'),
    labelClose: msgs.of('close'),
    labelSetting: msgs.of('setting'),
  };
}
