


















































import { defineComponent } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';

import { PropsCourseFlexCard, useCourseFlexCard } from './CourseFlexCardComposable';

type Props = PropsCourseFlexCard;

export default defineComponent({
  name: 'HomeCourseFlexCard',
  components: { BaseLink, CourseBackground },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    contentsCount: { type: Number, default: undefined },
    completedContentsCount: { type: Number, default: undefined },
    reviewProblemCount: { type: Number, default: undefined },
    isLearning: { type: Boolean, default: undefined },
    isExamOpen: { type: Boolean, default: undefined },
    isExamScheduled: { type: Boolean, default: undefined },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
    progressSize: { type: [String, Number], default: 110 },
    progressWidth: { type: [String, Number], default: 12 },
    progressTextClass: { type: String, default: 'text-h4' },
    chipXSmall: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    returnQuery: { type: Object, default: undefined },
    width: { type: [String, Number], default: undefined },
  },
  setup(props: Props) {
    return useCourseFlexCard(props);
  },
});
