
















import { defineComponent } from '@vue/composition-api';

import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';

import Courses from '../components/organisms/Courses.vue';

export default defineComponent({
  name: 'HomeGroupCoursesPage',
  components: { MyApp, Courses, ScrollToTopButton },
  props: {
    id: { type: String, required: true },
  },
});
