import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';

export type PropsCourseReviewFlexCard = {
  reviewProblemsCount: number;
  courseId: string;
  courseName: string;
  groupId: string;
  groupName: string;
  groupRole?: GroupRole;
  adminMode: boolean;
  returnQuery?: Record<string, undefined>;
};

export function useCourseReviewFlexCard(props: PropsCourseReviewFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.courseReviewFlexCard' });

  const title = computed(() => msgs.of('courseReview', { name: props.courseName }).value);
  const labelProblems = computed(
    () => msgs.of('problems', { size: props.reviewProblemsCount }).value
  );

  const disabled = computed(() => props.reviewProblemsCount <= 0);
  const toReviewingCourse = computed(() => ({
    name: 'groupCourseReview',
    params: { id: props.groupId, courseId: props.courseId },
    query: props.returnQuery,
  }));

  return {
    title,
    labelProblems,
    disabled,
    toReviewingCourse,
    labelStart: msgs.of('start'),
    description: msgs.of('courseReviewDescription'),
  };
}
