import { onMounted, ref, watch } from '@vue/composition-api';

export type BasePinsValue = string[];

export type BasePinsItem = {
  value: string;
  label: string;
};

export type PropsBasePins = {
  value: BasePinsValue;
  items: BasePinsItem[];
};

type Pin = BasePinsItem & { checked: boolean };

export function useBasePins(
  props: PropsBasePins,
  emit: (name: string, args: BasePinsValue) => void
) {
  const pins = ref<Pin[]>([]);
  function init() {
    pins.value = props.items.map((item) => ({
      ...item,
      checked: props.value.includes(item.value),
    }));
  }
  onMounted(init);
  watch(() => props.value, init);
  watch(() => props.items, init);

  function toggle(pin: Pin) {
    const v = pins.value
      .filter(
        (item) =>
          (item.checked && item.value !== pin.value) || (!item.checked && item.value === pin.value)
      )
      .map((item) => item.value);
    emit('change', v);
  }
  return { pins, toggle };
}
