import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';
import { GetCourseDetailsTraineeExam } from '@/home/usecases';

export type PropsUserExamFlexCard = GetCourseDetailsTraineeExam & {
  courseId: string;
  courseName: string;
  courseColor?: string;
  courseImage?: string;
  groupId: string;
  groupName?: string;
  groupRole?: GroupRole;
  adminMode: boolean;
  returnQuery?: Record<string, unknown>;
};

export function useUserExamFlexCard(props: PropsUserExamFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.userExamFlexCard' });

  const labelStatus = computed(() => {
    if (props.groupExamStatus === 'in_progress') return msgs.of(props.status).value;
    return msgs.of(props.groupExamStatus).value;
  });
  const labelStart = computed(() =>
    props.status === 'in_progress' ? msgs.of('restart').value : msgs.of('start').value
  );
  const labelExamTimes = computed(() => msgs.of('examTimes', { times: props.times }).value);
  const labelExamDate = computed(() => {
    if (props.finishedAt && props.status === 'finished')
      return msgs.of('finishedAt', { date: props.finishedAt.format('l') }).value;
    return msgs.of('startedAt', { date: props.scheduledStart.format('lll') }).value;
  });
  const labelTimeLimit = computed(() => {
    if (props.timeLimit === undefined || props.timeLimit === null)
      return msgs.of('noTimeLimit').value;
    return msgs.of('timeLimit', { min: props.timeLimit }).value;
  });

  const disabled = computed(
    () =>
      props.groupExamStatus === 'announced' ||
      props.status === 'finished' ||
      props.status === 'skipped'
  );

  const displayGroupUserExam = computed(() => {
    if (props.adminMode) return false;
    if (props.groupRole) return props.groupRole === 'trainee';
    return false;
  });
  const toGroupUserExam = computed(() => ({
    name: 'groupUserExam',
    params: { id: props.groupId, examId: props.id },
    query: props.returnQuery,
  }));

  return {
    labelStatus,
    labelStart,
    labelExamTimes,
    labelExamDate,
    labelTimeLimit,
    disabled,
    displayGroupUserExam,
    toGroupUserExam,
  };
}
