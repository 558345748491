import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { LocalDateTime } from '@/base/types';

export type PropsQuestionnaireBar = {
  id: string;
  title: string;
  by: UserAvatar;
  groupId: string;
  createdAt: LocalDateTime;
  unanswered?: boolean;
};

export function useQuestionnaireBar(props: PropsQuestionnaireBar) {
  const msgs = useMessages({ prefix: 'training.molecules.questionnaireBar' });
  const { getLocation } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE_ANS);

  const description = computed(() => {
    const name = props.by.name ?? msgs.of('unknown', { id: props.by.id }).value;
    return name;
  });

  const status = computed(() => {
    if (props.unanswered) return msgs.of('unanswered').value;
    return msgs.of('answered').value;
  });

  const to = computed(() => getLocation({ id: props.id }));

  return { description, status, to };
}
