import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { useGlobalStore } from '@/base/app/store';
import { GroupRole } from '@/base/domains';

type UserItem = {
  id: string;
  preview: string;
  avatar: UserAvatar;
  roleIndex: number;
  role: string;
  active?: string;
};

const ROLES: GroupRole[] = ['trainee', 'mentor', 'trainer'];

const HEADERS: DataTableHeader[] = [
  {
    value: 'preview',
    text: '',
    cellClass: 'd-flex align-center',
  },
  { value: 'role', text: '', width: 200 },
  { value: 'active', text: '', width: 200 },
];

export type PropsUsers = {
  groupId: string;
};

export function useUsers(props: PropsUsers) {
  const msgs = useMessages({ prefix: 'home.organisms.users' });
  const { group } = useGlobalStore();

  const headers = computed(() => HEADERS.map((h) => ({ ...h, text: msgs.of(h.value).value })));

  const items = computed<UserItem[]>(() => {
    if (props.groupId !== group.value?.id) return [];
    const labelActive = msgs.of('active').value;
    return group.value?.users
      .map((item) => ({
        id: item.id,
        preview: item.name,
        avatar: item,
        roleIndex: ROLES.indexOf(item.role),
        role: msgs.of(item.role).value,
        active: item.status === 'active' ? labelActive : undefined,
      }))
      .sort((a, b) => {
        if (a.role === b.role) return a.preview < b.preview ? -1 : 1;
        return a.roleIndex - b.roleIndex;
      });
  });

  return { headers, items };
}
