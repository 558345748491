import { computed, nextTick, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';

import { uuid } from '@/utils/UniqueIdGenerator';

import { smoothScroll, useScreen } from '../../utils/DomUtils';

type SlideItem = {
  id: string;
  index: number;
  visible: boolean;
  item: Record<string, unknown>;
};

export type PropsBaseSlideGroup = {
  items: Record<string, unknown>[];
  threshold: number;
  touchLess: boolean;
  dotsMin: number;
  dotsMax: number;
};

export function useBaseSlideGroup(props: PropsBaseSlideGroup) {
  const scrollArea = ref<HTMLDivElement>();

  const activeId = ref<string>();
  const slideItems = ref<SlideItem[]>([]);

  function changeVisible(id: string, visible: boolean) {
    const v = slideItems.value.find((item) => item.id === id);
    if (v && v.visible !== visible) Object.assign(v, { visible });
  }

  function calculateVisible() {
    const area = scrollArea.value;
    if (!area) return;
    const { left: areaLeft, right: areaRight } = area.getBoundingClientRect();
    Array.from(area.getElementsByClassName('base-slide-group-item')).forEach((div) => {
      const { id } = div;
      const { left, right } = div.getBoundingClientRect();
      const visible = areaLeft <= left && right <= areaRight;
      changeVisible(id, visible);
    });
    const last = slideItems.value.find(
      (item, i, arr) => item.visible && item.index === arr.length - 1
    );
    if (last) {
      activeId.value = last.id;
      return;
    }
    const first = slideItems.value.filter((item) => item.visible).shift();
    if (first) activeId.value = first.id;
  }

  function dispose() {
    if (scrollArea.value) scrollArea.value.removeEventListener('scroll', calculateVisible);
  }
  onUnmounted(dispose);

  function initItems() {
    slideItems.value = props.items.map((item, i) => ({
      id: uuid(),
      index: i,
      visible: false,
      item,
    }));
    nextTick(calculateVisible);
  }

  function init() {
    initItems();
    if (!scrollArea.value) return;
    scrollArea.value.addEventListener('scroll', calculateVisible);
  }
  onMounted(init);
  watch(() => props.items, initItems);

  function show(id: string) {
    const area = scrollArea.value;
    if (!area) return;
    const div = Array.from(area.getElementsByClassName('base-slide-group-item')).find(
      (item) => item.id === id
    );
    if (!div) return;
    const { x } = div.getBoundingClientRect();
    smoothScroll(x, area);
  }

  function prev() {
    const items = slideItems.value.filter((item) => item.visible);
    if (items.length > 0) {
      const [first] = items;
      const item = slideItems.value
        .slice(0, first.index)
        .slice(-1 * items.length)
        .shift();
      if (item) activeId.value = item.id;
    } else {
      const a = slideItems.value.find((item) => item.id === activeId.value);
      if (!a) return;
      const item = slideItems.value[a.index - 1];
      if (item) activeId.value = item.id;
    }
    if (!activeId.value) return;
    show(activeId.value);
  }

  function next() {
    const items = slideItems.value.filter((item) => item.visible);
    if (items.length > 0) {
      const [last] = items.slice(-1);
      const item = slideItems.value[last.index + 1];
      if (item) activeId.value = item.id;
    } else {
      const a = slideItems.value.find((item) => item.id === activeId.value);
      if (!a) return;
      const item = slideItems.value[a.index + 1];
      if (item) activeId.value = item.id;
    }
    if (!activeId.value) return;
    show(activeId.value);
  }

  const onTouch = computed(() => {
    if (navigator.maxTouchPoints === 0 || props.touchLess) return {};
    return { left: next, right: prev };
  });

  const moveStartedX = ref<number>();

  function onMouseDown(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    moveStartedX.value = e.clientX;
  }

  function onMouseUp(e: MouseEvent) {
    if (moveStartedX.value === undefined) return;
    e.preventDefault();
    e.stopPropagation();
    moveStartedX.value = undefined;
  }

  function onMouseMove(e: MouseEvent) {
    if (moveStartedX.value === undefined) return;
    e.preventDefault();
    e.stopPropagation();
    const { clientX } = e;
    const v = moveStartedX.value - clientX;
    if (Math.abs(v) < props.threshold) return;
    if (v > 0) next();
    else prev();
    moveStartedX.value = undefined;
  }

  const onMouse = computed(() => {
    if (navigator.maxTouchPoints > 0 || props.touchLess) return {};
    return {
      mousedown: onMouseDown,
      mouseup: onMouseUp,
      mousemove: onMouseMove,
    };
  });

  const disabledPrev = computed(() => {
    const [first] = slideItems.value;
    if (!first) return true;
    return first.visible || first.id === activeId.value;
  });
  const disabledNext = computed(() => {
    const [last] = slideItems.value.slice(-1);
    if (!last) return true;
    return last.visible || last.id === activeId.value;
  });

  const { isTouchScreen } = useScreen();
  const hideControl = computed(() => {
    if (isTouchScreen.value) return false;
    return disabledPrev.value && disabledNext.value;
  });
  const hideDots = computed(() => {
    if (isTouchScreen.value && props.items.length <= props.dotsMax) return false;
    return props.items.length < props.dotsMin || props.items.length > props.dotsMax;
  });

  return {
    scrollArea,
    activeId,
    slideItems,
    disabledPrev,
    disabledNext,
    hideControl,
    hideDots,
    onTouch,
    onMouse,
    prev,
    next,
  };
}
