



















import { defineComponent, PropType } from '@vue/composition-api';

import BaseLabel from '@/base/app/components/atoms/BaseLabel.vue';
import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import { LocalDateTime } from '@/base/types';

import { PropsQuestionnaireBar, useQuestionnaireBar } from './QuestionnaireBarComposable';

type Props = PropsQuestionnaireBar;

export default defineComponent({
  name: 'TrainingQuestionnaireBar',
  components: { BaseLabel, BaseLink, DateTimeNow, User },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    by: { type: Object as PropType<UserAvatar>, default: undefined },
    groupId: { type: String, required: true },
    createdAt: { type: Object as PropType<LocalDateTime>, required: true },
    unanswered: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useQuestionnaireBar(props);
  },
});
