var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 py-5"},[(_vm.loading)?_c('loading',{staticStyle:{"height":"calc(100vh - 200px)"},attrs:{"value":true}}):(_vm.groups.length > 0)?[(_vm.recommendCourses.length > 0)?_c('v-sheet',{staticClass:"px-10 py-5 mx-1 mb-10",attrs:{"outlined":""}},[_c('base-label',{staticClass:"text-h6 secondary--text mt-5",attrs:{"prepend-icon":"mdi-lightbulb-on-outline"}},[_vm._v(" "+_vm._s(_vm.isFirstRecommend ? _vm.labelStartLearning : _vm.labelRecommend)+" ")]),_c('base-markdown',{staticClass:"text-body-2 secondary--text px-5",attrs:{"body":_vm.recommendDescription}}),_c('base-slide-group',{attrs:{"items":_vm.recommendCourses},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('recommend-course-flex-card',_vm._b({staticClass:"ma-1",attrs:{"width":_vm.cardWidth,"height":"180"}},'recommend-course-flex-card',item,false))]}}],null,false,1059010940)})],1):_vm._e(),_c('base-slide-group',{attrs:{"items":_vm.groups},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('group-flex-card',_vm._b({staticClass:"ma-1",attrs:{"icon-size":"80","buttons-limit":_vm.groupButtonsLimit,"width":_vm.cardWidth,"min-height":"208","data-cy":"home.tenant.groupCard"}},'group-flex-card',item,false))]}}])}),(_vm.courses.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-bookshelf"}},[_vm._v(_vm._s(_vm.labelCourses))]),_c('base-slide-group',{attrs:{"items":_vm.courses},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('course-flex-card',_vm._b({staticClass:"ma-1",attrs:{"width":_vm.cardWidth,"height":"160","chip-x-small":""}},'course-flex-card',item,false))]}}],null,false,4120701073)})]:_vm._e(),(_vm.exams.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-hand-pointing-right"}},[_vm._v(_vm._s(_vm.labelExams))]),_c('base-slide-group',{attrs:{"items":_vm.exams},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('user-exam-flex-card',_vm._b({staticClass:"ma-1",attrs:{"return-query":_vm.returnQuery,"background-cols":1,"width":_vm.cardWidth,"height":"208","chip-x-small":""}},'user-exam-flex-card',item,false))]}}],null,false,2279527952)})]:_vm._e(),(_vm.questions.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-forum"}},[_vm._v(_vm._s(_vm.labelQuestions))]),_c('base-slide-group',{attrs:{"items":_vm.questions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('question-flex-card',_vm._b({staticClass:"ma-1",attrs:{"width":_vm.cardWidth,"height":"136"}},'question-flex-card',item,false))]}}],null,false,397838065)})]:_vm._e(),(_vm.unansweredQuestionnaires.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-hand-pointing-right"}},[_vm._v(" "+_vm._s(_vm.labelUnansweredQuestionnaires)+" ")]),_c('base-slide-group',{attrs:{"items":_vm.unansweredQuestionnaires},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('questionnaire-flex-card',_vm._b({staticClass:"ma-1",attrs:{"unanswered":"","width":_vm.cardWidth,"height":"136"}},'questionnaire-flex-card',item,false))]}}],null,false,1344699457)})]:_vm._e(),(_vm.questionnaires.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-chart-donut"}},[_vm._v(" "+_vm._s(_vm.labelQuestionnaires)+" ")]),_c('base-slide-group',{attrs:{"items":_vm.questionnaires},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('questionnaire-flex-card',_vm._b({staticClass:"ma-1",attrs:{"width":_vm.cardWidth,"height":"136"}},'questionnaire-flex-card',item,false))]}}],null,false,847689777)})]:_vm._e(),(_vm.schedules.length > 0)?[_c('base-label',{staticClass:"text-body-2 secondary--text my-5",attrs:{"prepend-icon":"mdi-calendar"}},[_vm._v(_vm._s(_vm.labelSchedules))]),_c('base-slide-group',{attrs:{"items":_vm.schedules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('schedule-flex-card',_vm._b({staticClass:"ma-1",attrs:{"width":_vm.cardWidth,"height":"180"},on:{"open":_vm.openSchedule}},'schedule-flex-card',item,false))]}}],null,false,1058533042)})]:_vm._e(),(_vm.noDataDescription)?_c('div',{staticClass:"my-10"},[_c('base-label',{staticClass:"text-body-2 secondary--text",attrs:{"prepend-icon":"mdi-information-outline"}},[_vm._v(_vm._s(_vm.noDataDescription))])],1):_vm._e()]:_c('div',{staticClass:"secondary--text text-center py-10"},[_vm._v(_vm._s(_vm.noGroupDescription))]),_c('schedule-dialog',{ref:"scheduleDialog",attrs:{"min-height":"80vh","max-width":"min(600px, calc(100vw - 20px))"},on:{"click-anchor":_vm.clickAnchor,"click-user":function () {}}}),_c('dialog-anchor-confirm',{ref:"anchorDialog",attrs:{"max-width":"300"}}),_c('confirm-terms-of-service-dialog',{attrs:{"width":"min(400px, 80vw)"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }