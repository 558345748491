import { computed, onMounted, Ref, ref, watch } from '@vue/composition-api';
import { useWindowSize } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { createUserAvatar } from '@/base/app/components/atoms/UserComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { useGlobalStore } from '@/base/app/store';
import { GlobalStoreLimitationName } from '@/base/app/store/types';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  GetMobileHomeContentsCourse,
  GetMobileHomeContentsExam,
  useGetMobileHomeContents,
} from '../../../usecases';
import { PropsGroupFlexCard } from '../molecules/GroupFlexCardComposable';
import { PropsQuestionFlexCard } from '../molecules/QuestionFlexCardComposable';
import { PropsQuestionnaireFlexCard } from '../molecules/QuestionnaireFlexCardComposable';
import {
  ScheduleDialog,
  ScheduleDialogClickAnchorPayload,
} from '../molecules/ScheduleDialogComposable';
import {
  PropsScheduleFlexCard,
  ScheduleFlexCardOpenPayload,
} from '../molecules/ScheduleFlexCardComposable';

const LIMIT = 10;
const GROUP_MENU_LIMIT = 5;

type HomeGroup = PropsGroupFlexCard;

type HomeQuestion = PropsQuestionFlexCard;

type HomeQuestionnaire = PropsQuestionnaireFlexCard;

type HomeSchedule = PropsScheduleFlexCard;

function useScheduleDialog(schedules: Ref<HomeSchedule[]>) {
  const { findUser } = useGlobalStore();
  const scheduleDialog = ref<ScheduleDialog>();
  function open(payload: ScheduleFlexCardOpenPayload) {
    assertIsDefined(scheduleDialog.value);
    const schedule = schedules.value.find((item) => item.id === payload.id);
    if (!schedule) return;
    const updatedBy = schedule.updatedBy ?? schedule.createdBy;
    scheduleDialog.value.open({
      schedule: {
        ...schedule,
        updatedBy,
        updatedByName: updatedBy ? findUser(updatedBy)?.name : undefined,
      },
    });
  }
  return { scheduleDialog, open };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: ScheduleDialogClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export function useTenant() {
  const msgs = useMessages({ prefix: 'home.organisms.tenant' });
  const { user, locale, findUser, findGroup, getGroupMenusAvailable } = useGlobalStore();

  const route = useRoute();
  const router = useRouter();
  const returnQuery = computed(() => toQuery(route));

  const groups = ref<HomeGroup[]>([]);
  const groupButtonsLimit = computed(() => (locale.value === 'ja' ? 9 : 5));
  const courses = ref<GetMobileHomeContentsCourse[]>([]);
  const exams = ref<GetMobileHomeContentsExam[]>([]);
  const questions = ref<HomeQuestion[]>([]);
  const unansweredQuestionnaires = ref<HomeQuestionnaire[]>([]);
  const questionnaires = ref<HomeQuestionnaire[]>([]);
  const schedules = ref<HomeSchedule[]>([]);
  const recommendCourses = ref<GetMobileHomeContentsCourse[]>([]);
  const isFirstRecommend = ref(false);

  const loading = ref(false);

  function getRecommendCourses(allCourse: GetMobileHomeContentsCourse[]): {
    courses: GetMobileHomeContentsCourse[];
    isFirst: boolean;
  } {
    assertIsDefined(user.value, 'user');
    const { groups: userGroups } = user.value;
    const items = userGroups
      .filter((g) => g.role === 'trainee')
      .map(({ id }) => {
        const gcs = allCourse.filter((c) => c.groupId === id);

        // 未学習の最小のコースを取得
        const [first] = gcs
          .filter((c) => !c.completedContentsCount)
          .sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
        if (!first) return [];

        const completedCount = gcs.filter(
          (c) => c.contentsCount === c.completedContentsCount
        ).length;
        return [{ isLearning: completedCount > 0, first }];
      })
      .flat();
    if (items.some((item) => item.isLearning)) {
      return {
        courses: items.filter((item) => item.isLearning).map((item) => item.first),
        isFirst: false,
      };
    }
    return { courses: items.map((item) => item.first), isFirst: true };
  }

  const getMobileHomeContents = useGetMobileHomeContents();
  async function fetch() {
    assertIsDefined(user.value, 'user');
    const { role: userRole, groups: userGroups } = user.value;

    loading.value = true;
    const res = await getMobileHomeContents.execute({});
    if (isFailed(res)) {
      groups.value = [];
      courses.value = [];
      exams.value = [];
      questions.value = [];
      unansweredQuestionnaires.value = [];
      questionnaires.value = [];
      schedules.value = [];
      recommendCourses.value = [];
      isFirstRecommend.value = false;
      return;
    }
    loading.value = false;

    const getGroupNameAndRole = (id: string) => {
      const g = userGroups.find((item) => item.id === id);
      if (!g) return {};
      return { groupName: g.name, groupRole: g.role };
    };

    groups.value =
      userGroups
        .map(({ id, name, role: groupRole }) => {
          return {
            id,
            name,
            groupRole,
            description: findGroup(id)?.description ?? '',
            adminMode: false,
            role: userRole,
            menusAvailable: getGroupMenusAvailable(false, id),
            counts: res.count.get(id),
            buttonsLimit: GROUP_MENU_LIMIT,
          };
        })
        .sort((a, b) => {
          if (!!a.role !== !!b.role) return a.role ? -1 : 1;
          return a.name < b.name ? -1 : 1;
        }) ?? [];
    courses.value = res.courses
      .filter((item) => item.isLearning)
      .map((item) => ({ ...item, ...getGroupNameAndRole(item.groupId) }))
      .slice(0, LIMIT);
    exams.value = res.exams
      .map((item) => ({ ...item, ...getGroupNameAndRole(item.groupId) }))
      .sort((a, b) => (a.scheduledStart.isBefore(b.scheduledStart) ? -1 : 1))
      .slice(0, LIMIT);
    questions.value = res.questions
      .map((item) => {
        const [comment] = item.comments
          .map((c) => ({
            id: c.id,
            at: c.editedAt ?? c.createdAt,
            by: createUserAvatar(c.editedBy ?? c.createdBy, findUser),
            updated: !!c.editedAt,
          }))
          .sort((a, b) => (a.at.isAfter(b.at) ? -1 : 1));
        return {
          comment,
          id: item.id,
          title: item.title,
          groupId: item.groupId,
          ...getGroupNameAndRole(item.groupId),
        };
      })
      .sort((a, b) => (a.comment.at.isAfter(b.comment.at) ? -1 : 1))
      .slice(0, LIMIT);
    unansweredQuestionnaires.value = res.unansweredQuestionnaires
      .map((item) => ({
        id: item.id,
        title: item.title,
        by: createUserAvatar(item.createdBy, findUser),
        at: item.createdAt,
        groupId: item.groupId,
        ...getGroupNameAndRole(item.groupId),
      }))
      .sort((a, b) => (a.at.isAfter(b.at) ? -1 : 1))
      .slice(0, LIMIT);
    questionnaires.value = res.questionnaires
      .map((item) => ({
        id: item.id,
        title: item.title,
        by: createUserAvatar(item.createdBy, findUser),
        at: item.createdAt,
        groupId: item.groupId,
        ...getGroupNameAndRole(item.groupId),
      }))
      .sort((a, b) => (a.at.isAfter(b.at) ? -1 : 1))
      .slice(0, LIMIT);
    schedules.value = res.schedules
      .map((item) => ({ ...item, ...getGroupNameAndRole(item.groupId) }))
      .sort((a, b) => (a.start.isBefore(b.start) ? -1 : 1))
      .slice(0, LIMIT);
    if (courses.value.length === 0) {
      const recommend = getRecommendCourses(res.courses);
      recommendCourses.value = recommend.courses
        .map((item) => ({
          ...item,
          ...getGroupNameAndRole(item.groupId),
        }))
        .sort((a, b) => ((a.groupName ?? '') < (b.groupName ?? '') ? -1 : 1));
      isFirstRecommend.value = recommend.isFirst;
    } else {
      recommendCourses.value = [];
      isFirstRecommend.value = false;
    }
  }
  onMounted(fetch);
  watch(
    () => route.query,
    (newVal) => {
      if (!newVal) return;
      if ('f' in newVal) {
        fetch();
        router.replace({ name: 'home' });
      }
    }
  );

  const { scheduleDialog, open: openSchedule } = useScheduleDialog(schedules);

  const { width } = useWindowSize();
  const cardWidth = computed(() => {
    if (width.value < 450) return 'calc(100vw - 32px)';
    return '320px';
  });

  const noDataDescription = computed(() => {
    const keys: string[] = [];
    const hasMenu = (name: GlobalStoreLimitationName) =>
      groups.value.some((g) => g.menusAvailable.includes(name));
    if (courses.value.length === 0) keys.push('courses');
    if (exams.value.length === 0) keys.push('exams');
    if (questions.value.length === 0 && hasMenu('question')) keys.push('questions');
    if (unansweredQuestionnaires.value.length === 0 && hasMenu('questionnaire'))
      keys.push('unansweredQuestionnaires');
    if (
      questionnaires.value.length === 0 &&
      user.value?.groups.some((g) => g.role === 'trainer' || g.role === 'mentor') &&
      hasMenu('questionnaire')
    )
      keys.push('questionnaires');
    if (schedules.value.length === 0 && hasMenu('schedule')) keys.push('schedules');
    if (keys.length === 0) return undefined;
    return msgs.of('noData', { labels: keys.map((k) => msgs.of(k).value).join(',') }).value;
  });

  return {
    returnQuery,
    groups,
    groupButtonsLimit,
    loading,
    courses,
    exams,
    questions,
    unansweredQuestionnaires,
    questionnaires,
    schedules,
    recommendCourses,
    isFirstRecommend,
    cardWidth,
    scheduleDialog,
    labelGroups: msgs.of('groups'),
    labelCourses: msgs.of('courses'),
    labelExams: msgs.of('exams'),
    labelQuestions: msgs.of('questions'),
    labelUnansweredQuestionnaires: msgs.of('unansweredQuestionnaires'),
    labelQuestionnaires: msgs.of('questionnaires'),
    labelSchedules: msgs.of('schedules'),
    labelOpenSchedule: msgs.of('detail'),
    labelRecommend: msgs.of('recommend'),
    labelStartLearning: msgs.of('startLearning'),
    recommendDescription: msgs.of('recommendDescription'),
    noGroupDescription: msgs.of('noGroup'),
    noDataDescription,
    openSchedule,
    ...useAnchorDialog(),
  };
}
