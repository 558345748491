import { computed, ref } from '@vue/composition-api';

import { useMessages } from '../..';
import { useGlobalStore } from '../../store';

export type PropsGroupLinkMenu = {
  groupId: string;
  routeName: string;
  adminMode: boolean;
};

export function useGroupLinkMenu(props: PropsGroupLinkMenu) {
  const msgs = useMessages({ prefix: 'base.organisms.groupLinkMenu' });
  const { user, accessibleGroups } = useGlobalStore();
  const groups = computed(() => {
    const groupList = props.adminMode ? accessibleGroups.value : user.value?.groups;
    return groupList
      ?.map((item) => ({ ...item, to: { name: props.routeName, params: { id: item.id } } }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  });
  const menu = ref(false);
  return { groups, menu, label: msgs.of('groups'), labelBack: msgs.of('back') };
}
