import { computed, ref } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import { GlobalStoreLimitationName } from '@/base/app/store/types';
import { GroupRole, Role } from '@/base/domains';

type MenuRole = {
  eqAdminMode: boolean;
  roles: (GroupRole | Role)[];
};

export type GroupFlexCardCounts = {
  notCompletedCourseCount: number;
  questionCount: number;
  scheduleCount: number;
  questionnaireCount: number;
  unansweredQuestionnaireCount: number;
  hasReviewProblemsCourseCount: number;
};
type CountKey = keyof GroupFlexCardCounts;

const MENUS: {
  value: string;
  icon: string;
  countKey?: CountKey;
  limitation?: GlobalStoreLimitationName;
  inRoles?: MenuRole[];
  to?: Location;
}[] = [
  {
    value: 'groupCourses',
    icon: 'mdi-bookshelf',
    countKey: 'notCompletedCourseCount',
  },
  {
    value: 'groupCoursesExistsReview',
    icon: 'mdi-book',
    countKey: 'hasReviewProblemsCourseCount',
    inRoles: [{ eqAdminMode: false, roles: ['trainer', 'mentor', 'trainee'] }],
    to: { name: 'groupCourses', query: { f: JSON.stringify({ c: ['existsCourseReview'] }) } },
  },
  {
    value: 'trainerGroupQuestionnaires',
    icon: 'mdi-chart-donut',
    countKey: 'questionnaireCount',
    limitation: 'questionnaire',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: ['admin', 'supervisor'] },
    ],
  },
  {
    value: 'groupQuestionnaires',
    icon: 'mdi-order-bool-descending',
    countKey: 'unansweredQuestionnaireCount',
    limitation: 'questionnaire',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor', 'trainee'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
  {
    value: 'groupQuestions',
    icon: 'mdi-forum',
    countKey: 'questionCount',
    limitation: 'question',
  },
  {
    value: 'groupSchedules',
    icon: 'mdi-calendar',
    countKey: 'scheduleCount',
    limitation: 'schedule',
  },
  {
    value: 'groupExamResultHistory',
    icon: 'mdi-text-box-check-outline',
    inRoles: [
      { eqAdminMode: false, roles: ['trainee'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
  {
    value: 'trainerGroupExamResultHistory',
    icon: 'mdi-text-box-check-outline',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: ['admin', 'supervisor'] },
    ],
  },
  { value: 'groupUsers', icon: 'mdi-account' },
  {
    value: 'trainerGroupCourses',
    icon: 'mdi-cog',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
];

export type PropsGroupFlexCard = {
  id: string;
  name: string;
  description?: string;
  groupRole?: GroupRole;
  adminMode: boolean;
  role: Role;
  menusAvailable: GlobalStoreLimitationName[];
  counts?: GroupFlexCardCounts;
  buttonsLimit: number;
};

export function useGroupFlexCard(props: PropsGroupFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.groupFlexCard' });
  const menu = ref(false);

  const menus = computed(() => {
    const { menusAvailable, adminMode, role, groupRole: gRole } = props;
    return MENUS.filter(
      (m) =>
        (!m.limitation || menusAvailable.includes(m.limitation)) &&
        (!m.inRoles ||
          m.inRoles.some(
            ({ eqAdminMode, roles }) =>
              eqAdminMode === adminMode &&
              (roles.includes(role) || (gRole && roles.includes(gRole)))
          ))
    ).map((m) => ({
      icon: m.icon,
      label: msgs.of(m.value).value,
      value: m.value,
      to: m.to
        ? { ...m.to, params: { id: props.id } }
        : { name: m.value, params: { id: props.id } },
      count: m.countKey && props.counts ? props.counts[m.countKey] : undefined,
    }));
  });

  const buttons = computed(() => {
    const primary = menus.value
      .filter((item) => !!item.count)
      .map((item) => item.value)
      .slice(0, props.buttonsLimit);
    const secondary = props.buttonsLimit - primary.length;
    if (secondary > 0)
      primary.push(
        ...menus.value
          .filter((item) => !item.count)
          .map((item) => item.value)
          .slice(0, secondary)
      );
    return menus.value.filter((item) => primary.includes(item.value));
  });
  const others = computed(() =>
    menus.value.filter((item) => !buttons.value.some((b) => b.value === item.value))
  );

  const labelRole = computed(() => (props.groupRole ? msgs.of(props.groupRole).value : undefined));

  return {
    menu,
    menus: others,
    buttons,
    labelRole,
    labelNotBelong: msgs.of('notBelong'),
    labelMenu: msgs.of('others'),
  };
}
