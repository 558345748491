














































import { defineComponent, PropType } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';
import { LocalDateTime } from '@/base/types';

import { PropsGroupExamFlexCard, useGroupExamFlexCard } from './GroupExamFlexCardComposable';

type Props = PropsGroupExamFlexCard;

export default defineComponent({
  name: 'HomeGroupExamFlexCard',
  components: { BaseButton, BaseLink, CourseBackground },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    contentId: { type: String, required: true },
    contentName: { type: String, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    scheduledStart: { type: Object as PropType<LocalDateTime>, required: true },
    scheduledFinish: { type: Object as PropType<LocalDateTime>, default: undefined },
    status: { type: String, required: true },
    times: { type: Number, required: true },
    timeLimit: { type: Number, default: undefined },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
    groupRole: { type: String, default: undefined },
    adminMode: { type: Boolean, default: false },
    backgroundCols: { type: Number, default: 3 },
    returnQuery: { type: Object, default: undefined },
  },
  setup(props: Props) {
    return useGroupExamFlexCard(props);
  },
});
