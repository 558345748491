import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';

export type PropsQuestionnaireFlexCard = {
  id: string;
  title: string;
  by: UserAvatar;
  groupId: string;
  unanswered?: boolean;
};

export function useQuestionnaireFlexCard(props: PropsQuestionnaireFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.questionnaireFlexCard' });
  const { getLocation: getQuestionnaireLocation } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE);
  const { getLocation: getQuestionnaireAnswerLocation } = useDialogQuery(
    DialogName.BASE_QUESTIONNAIRE_ANS
  );

  const description = computed(() => {
    const name = props.by.name ?? msgs.of('unknown', { id: props.by.id }).value;
    return msgs.of('started', { name }).value;
  });

  const to = computed(() =>
    props.unanswered
      ? getQuestionnaireAnswerLocation({ id: props.id })
      : getQuestionnaireLocation({ id: props.id })
  );

  return { description, to };
}
