






















































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import {
  PropsCourseReviewFlexCard,
  useCourseReviewFlexCard,
} from './CourseReviewFlexCardComposable';

type Props = PropsCourseReviewFlexCard;

export default defineComponent({
  name: 'HomeCourseReviewFlexCard',
  components: { BaseButton, BaseLink, CourseBackground, HintIcon },
  inheritAttrs: false,
  props: {
    reviewProblemsCount: { type: Number, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    open: { type: Boolean, default: true },
    groupId: { type: String, required: true },
    groupName: { type: String, required: true },
    adminMode: { type: Boolean, default: false },
    closeIconSize: { type: [Number, String], default: 100 },
    backgroundCols: { type: Number, default: 3 },
    returnQuery: { type: Object, default: undefined },
  },
  setup(props: Props) {
    return useCourseReviewFlexCard(props);
  },
});
