



































import { defineComponent, PropType } from '@vue/composition-api';

import { PropsBaseSlideGroup, useBaseSlideGroup } from './BaseSlideGroupComposable';

type Props = PropsBaseSlideGroup;

export default defineComponent({
  name: 'BaseBaseSlideGroup',
  props: {
    items: { type: Array as PropType<Record<string, unknown>[]>, default: () => [] },
    threshold: { type: Number, default: 50 },
    touchLess: { type: Boolean, default: false },
    dotsMin: { type: Number, default: 4 },
    dotsMax: { type: Number, default: 15 },
  },
  setup(props: Props) {
    return useBaseSlideGroup(props);
  },
});
