







import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import Users from '../components/organisms/Users.vue';

export default defineComponent({
  name: 'HomeGroupUsersPage',
  components: { MyApp, Users },
  props: {
    id: { type: String, required: true },
  },
});
