import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';
import { GetMobileHomeContentsExam } from '@/home/usecases';

export type PropsGroupExamFlexCard = GetMobileHomeContentsExam & {
  groupName?: string;
  groupRole?: GroupRole;
  adminMode: boolean;
  returnQuery?: Record<string, unknown>;
};

export function useGroupExamFlexCard(props: PropsGroupExamFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.groupExamFlexCard' });

  const labelStatus = computed(() => msgs.of(props.status).value);
  const labelExamTimes = computed(() => msgs.of('examTimes', { times: props.times }).value);
  const labelStartedAt = computed(
    () => msgs.of('startedAt', { date: props.scheduledStart.format('l') }).value
  );
  const labelTimeLimit = computed(() => {
    if (props.timeLimit === undefined || props.timeLimit === null)
      return msgs.of('noTimeLimit').value;
    return msgs.of('timeLimit', { min: props.timeLimit }).value;
  });

  const displayGroupCourse = computed(() => {
    if (props.adminMode) return true;
    if (props.groupRole) return props.groupRole !== 'trainee';
    return false;
  });
  const toGroupContent = computed(() => ({
    name: 'groupContent',
    params: { id: props.groupId, courseId: props.courseId, contentId: props.contentId },
    query: props.returnQuery,
  }));

  const displayGroupExams = computed(() => {
    if (props.adminMode) return true;
    if (props.groupRole) return props.groupRole !== 'trainee';
    return false;
  });
  const toGroupExams = computed(() => {
    const query = { courseId: props.courseId, contentId: props.contentId };
    if (props.groupRole === 'trainer') Object.assign(query, { edit: '' });
    if (props.returnQuery) Object.assign(query, props.returnQuery);
    return { name: 'trainerGroupExams', params: { id: props.groupId }, query };
  });

  return {
    labelStatus,
    labelExamTimes,
    labelStartedAt,
    labelTimeLimit,
    displayGroupCourse,
    toGroupContent,
    displayGroupExams,
    toGroupExams,
    labelSetting: msgs.of('setting'),
  };
}
