
















import { defineComponent, PropType } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';

import {
  PropsQuestionnaireFlexCard,
  useQuestionnaireFlexCard,
} from './QuestionnaireFlexCardComposable';

type Props = PropsQuestionnaireFlexCard;

export default defineComponent({
  name: 'HomeQuestionnaireFlexCard',
  components: { BaseLink, User },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    by: { type: Object as PropType<UserAvatar>, default: undefined },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
    unanswered: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useQuestionnaireFlexCard(props);
  },
});
