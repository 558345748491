
























import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import { useConfirmTermsOfServiceDialog } from './ConfirmTermsOfServiceDialogComposable';

export default defineComponent({
  name: 'HomeConfirmTermsOfServiceDialog',
  components: {
    BaseButton,
    BaseMarkdown,
    BaseLink,
    ErrorMessages,
  },
  inheritAttrs: false,
  setup() {
    return useConfirmTermsOfServiceDialog();
  },
});
