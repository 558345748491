






































import { defineComponent, PropType } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import BasePins from '@/base/app/components/molecules/BasePins.vue';
import { FrameRootDisplayMode } from '@/base/app/components/organisms/FrameRootComposable';
import GroupLinkMenu from '@/base/app/components/organisms/GroupLinkMenu.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import CourseFlexCard from '../molecules/CourseFlexCard.vue';
import { PropsCourses, useCourses } from './CoursesComposable';

type Props = PropsCourses;

export default defineComponent({
  name: 'HomeCourses',
  components: { Loading, BaseText, BasePins, CourseFlexCard, GroupLinkMenu },
  props: {
    groupId: { type: String, required: true },
    adminMode: { type: Boolean, default: false },
    displayMode: { type: String as PropType<FrameRootDisplayMode>, default: undefined },
  },
  setup(props: Props) {
    return useCourses(props);
  },
});
