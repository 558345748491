import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { LocalDateTime } from '@/base/types';

export type QuestionFlexCardComment = {
  id: string;
  by: UserAvatar;
  at: LocalDateTime;
  updated: boolean;
};

export type PropsQuestionFlexCard = {
  id: string;
  title: string;
  comment: QuestionFlexCardComment;
  groupId: string;
  groupName?: string;
};

export function useQuestionFlexCard(props: PropsQuestionFlexCard) {
  const msgs = useMessages({ prefix: 'home.molecules.questionFlexCard' });

  const description = computed(() => {
    const name = props.comment.by.name ?? msgs.of('unknown', { id: props.comment.by.id }).value;
    if (props.comment.updated) return msgs.of('updated', { name }).value;
    return msgs.of('created', { name }).value;
  });

  const to = computed(() => ({
    name: 'groupQuestion',
    params: { id: props.groupId, questionId: props.id },
    hash: props.comment.id,
  }));

  return { description, to };
}
