









































































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';

import { PropsContentFlexCard, useContentFlexCard } from './ContentFlexCardComposable';

type Props = PropsContentFlexCard;

export default defineComponent({
  name: 'HomeContentFlexCard',
  components: { BaseButton, BaseButtonIcon, BaseLink, CourseBackground },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    name: { type: String, required: true },
    index: { type: Number, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    learningStatus: { type: String, required: true },
    open: { type: Boolean, required: true },
    requiredTime: { type: Number, default: undefined },
    groupId: { type: String, required: true },
    groupName: { type: String, required: true },
    groupRole: { type: String, default: undefined },
    adminMode: { type: Boolean, default: false },
    closeIconSize: { type: [Number, String], default: 100 },
    backgroundCols: { type: Number, default: 3 },
    returnQuery: { type: Object, default: undefined },
  },
  setup(props: Props) {
    return useContentFlexCard(props);
  },
});
