














import { defineComponent } from '@vue/composition-api';

import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';

import Questionnaires from '../components/organisms/Questionnaires.vue';

export default defineComponent({
  name: 'TrainingGroupQuestionnairesPage',
  components: { MyApp, Questionnaires, ScrollToTopButton },
  props: {
    id: { type: String, required: true },
  },
});
