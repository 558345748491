import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { FrameRootDisplayMode } from '@/base/app/components/organisms/FrameRootComposable';
import { or } from '@/base/app/utils/FilterUtils';
import { isFailed } from '@/base/usecases';
import { GetCoursesCourse, useGetCourses } from '@/home/usecases';
import { useRoute, useRouter } from '@/utils/VueUtils';

type CoursesCourse = GetCoursesCourse;

export type PropsCourses = {
  groupId: string;
  adminMode: boolean;
  displayMode: FrameRootDisplayMode;
};

export function useCourses(props: PropsCourses) {
  const msgs = useMessages({ prefix: 'home.organisms.courses' });

  const route = useRoute();
  const router = useRouter();
  const returnQuery = computed(() => toQuery(route));

  const loading = ref(false);
  const courses = ref<CoursesCourse[]>([]);
  const condition = ref<string[]>([]);
  const searchText = ref<string>();

  const getCourses = useGetCourses();
  async function fetch() {
    loading.value = true;
    const res = await getCourses.execute({ groupId: props.groupId });
    loading.value = false;
    if (isFailed(res)) {
      courses.value = [];
      return;
    }
    courses.value = res.courses;
  }

  function parseQuery() {
    if (!route.query || !('f' in route.query)) return;
    const { f, ...q } = route.query;
    if (!f || typeof f !== 'string') return;
    try {
      const data = JSON.parse(f);
      if ('c' in data && Array.isArray(data.c)) {
        condition.value = data.c;
      }
      router.replace({ name: 'groupCourses', params: route.params, query: q });
    } catch (e) {
      // no action
    }
  }

  async function init() {
    condition.value = msgs.listOf('status').value.map((item) => item.value);
    parseQuery();
    fetch();
  }
  onMounted(init);
  watch(() => props.groupId, fetch);

  const filteredCourses = computed(() =>
    courses.value.filter((item) => {
      if (or(item.name, searchText.value) === false) return false;
      if (condition.value.includes('existsExamInProgress') && item.isExamOpen) return true;
      if (condition.value.includes('existsExamScheduled') && item.isExamScheduled) return true;
      if (condition.value.includes('courseInProgress') && item.isLearning) return true;
      if (
        condition.value.includes('courseNotBegun') &&
        !item.isLearning &&
        item.contentsCount !== item.completedContentsCount
      )
        return true;
      if (
        condition.value.includes('courseCompleted') &&
        !item.isLearning &&
        item.contentsCount === item.completedContentsCount
      )
        return true;
      if (condition.value.includes('existsCourseReview') && item.reviewProblemCount > 0)
        return true;
      return false;
    })
  );

  const isDesktopMode = computed(() => props.displayMode === 'desktop');

  return {
    returnQuery,
    loading,
    condition,
    searchText,
    courses: filteredCourses,
    isDesktopMode,
    labelSearch: msgs.of('search'),
    noDataText: msgs.of('noData'),
    statusList: msgs.listOf('status'),
  };
}
