











































































































































import { defineComponent } from '@vue/composition-api';

import BaseLabel from '@/base/app/components/atoms/BaseLabel.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseSlideGroup from '@/base/app/components/molecules/BaseSlideGroup.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import CourseFlexCard from '../molecules/CourseFlexCard.vue';
import GroupFlexCard from '../molecules/GroupFlexCard.vue';
import QuestionFlexCard from '../molecules/QuestionFlexCard.vue';
import QuestionnaireFlexCard from '../molecules/QuestionnaireFlexCard.vue';
import RecommendCourseFlexCard from '../molecules/RecommendCourseFlexCard.vue';
import ScheduleDialog from '../molecules/ScheduleDialog.vue';
import ScheduleFlexCard from '../molecules/ScheduleFlexCard.vue';
import UserExamFlexCard from '../molecules/UserExamFlexCard.vue';
import ConfirmTermsOfServiceDialog from './ConfirmTermsOfServiceDialog.vue';
import { useTenant } from './TenantComposable';

export default defineComponent({
  name: 'HomeTenant',
  components: {
    BaseLabel,
    BaseSlideGroup,
    GroupFlexCard,
    RecommendCourseFlexCard,
    CourseFlexCard,
    UserExamFlexCard,
    QuestionFlexCard,
    QuestionnaireFlexCard,
    ScheduleFlexCard,
    Loading,
    ScheduleDialog,
    DialogAnchorConfirm,
    ConfirmTermsOfServiceDialog,
    BaseMarkdown,
  },
  setup() {
    return useTenant();
  },
});
