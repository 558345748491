











import { defineComponent } from '@vue/composition-api';

import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';

import Tenant from '../components/organisms/Tenant.vue';
import TenantForAdmin from '../components/organisms/TenantForAdmin.vue';

export default defineComponent({
  name: 'HomeHomePage',
  components: { MyApp, TenantForAdmin, Tenant, ScrollToTopButton },
  setup() {
    return {};
  },
});
