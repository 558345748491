import { computed, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  MentionMarkdownClickAnchorPayload,
  MentionMarkdownClickMentionPayload,
} from '@/base/app/components/organisms/MentionMarkdownComposable';
import { Schedule } from '@/base/domains';

type ScheduleDialogSchedule = Schedule & {
  groupName?: string;
  updatedByName?: string;
};

export type ScheduleDialogClickAnchorPayload = MentionMarkdownClickAnchorPayload;

export type ScheduleDialogClickUserPayload = MentionMarkdownClickMentionPayload & {
  groupId?: string;
};

export function useScheduleDialog(
  emit: (
    name: string,
    arg: ScheduleDialogClickAnchorPayload | ScheduleDialogClickUserPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'home.molecules.scheduleDialog' });

  const dialog = ref(false);
  const schedule = ref<ScheduleDialogSchedule>();

  function close() {
    dialog.value = false;
    schedule.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  function open(target: { schedule: ScheduleDialogSchedule }) {
    schedule.value = target.schedule;
    dialog.value = true;
  }

  function clickAnchor(payload: MentionMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickMention(payload: MentionMarkdownClickMentionPayload) {
    emit('click-user', { ...payload, groupId: schedule.value?.groupId });
  }

  const start = computed(() => schedule.value?.start.format('ll [(]dd[)] HH:mm') ?? '');
  const end = computed(() => schedule.value?.end?.format('HH:mm') ?? '');

  const updatedByName = computed(() => {
    const id = schedule.value?.updatedBy;
    if (!id) return undefined;
    const name = schedule.value?.updatedByName || msgs.of('unknown', { id }).value;
    return msgs.of('updatedBy', { name }).value;
  });

  return {
    dialog,
    schedule,
    start,
    end,
    updatedByName,
    labelClose: msgs.of('close'),
    close,
    open,
    clickAnchor,
    clickMention,
  };
}

export type ScheduleDialog = ReturnType<typeof useScheduleDialog>;
