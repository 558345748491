









































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ScheduleTagVue from '@/base/app/components/atoms/ScheduleTag.vue';
import { ScheduleTag } from '@/base/domains';
import { LocalDateTime } from '@/base/types';

import { PropsScheduleFlexCard, useScheduleFlexCard } from './ScheduleFlexCardComposable';

type Props = PropsScheduleFlexCard;

export default defineComponent({
  name: 'HomeScheduleFlexCard',
  components: { BaseButton, ScheduleTag: ScheduleTagVue },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    start: { type: Object as PropType<LocalDateTime>, required: true },
    end: { type: Object as PropType<LocalDateTime>, default: undefined },
    title: { type: String, required: true },
    body: { type: String, default: undefined },
    createdBy: { type: String, default: undefined },
    updatedBy: { type: String, default: undefined },
    tags: { type: Array as PropType<ScheduleTag[]>, default: () => [] },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
  },
  emits: ['open'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleFlexCard(props, emit);
  },
});
