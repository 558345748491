
















import { defineComponent, PropType } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import User from '@/base/app/components/atoms/User.vue';

import {
  PropsQuestionFlexCard,
  QuestionFlexCardComment,
  useQuestionFlexCard,
} from './QuestionFlexCardComposable';

type Props = PropsQuestionFlexCard;

export default defineComponent({
  name: 'QuestionFlexCard',
  components: { BaseLink, User },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    comment: { type: Object as PropType<QuestionFlexCardComment>, required: true },
    groupId: { type: String, required: true },
    groupName: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useQuestionFlexCard(props);
  },
});
