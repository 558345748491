






































































































































































import { defineComponent } from '@vue/composition-api';

import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import ContentFlexCard from '../molecules/ContentFlexCard.vue';
import CourseFlexCard from '../molecules/CourseFlexCard.vue';
import CourseReviewFlexCard from '../molecules/CourseReviewFlexCard.vue';
import GroupExamFlexCard from '../molecules/GroupExamFlexCard.vue';
import UserExamFlexCard from '../molecules/UserExamFlexCard.vue';
import { PropsCourse, useCourse } from './CourseComposable';

type Props = PropsCourse;

export default defineComponent({
  name: 'HomeCourse',
  components: {
    Loading,
    BaseMarkdown,
    CourseFlexCard,
    ContentFlexCard,
    UserExamFlexCard,
    GroupExamFlexCard,
    CourseReviewFlexCard,
  },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    adminMode: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useCourse(props);
  },
});
