import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { useGlobalStore } from '@/base/app/store';
import { isFailed } from '@/base/usecases';
import { GetCourseDetailsResponse, useGetCourseDetails } from '@/home/usecases';
import { useRoute } from '@/utils/VueUtils';

export type PropsCourse = {
  groupId: string;
  courseId: string;
  adminMode: boolean;
};

export function useCourse(props: PropsCourse) {
  const msgs = useMessages({ prefix: 'home.organisms.course' });
  const route = useRoute();
  const returnQuery = computed(() => toQuery(route, route.query));

  const { group, groupRole } = useGlobalStore();
  const groupName = computed(() => group.value?.name);

  const menu = ref(false);
  const loading = ref(false);
  const details = ref<GetCourseDetailsResponse>();
  const courses = computed(() =>
    details.value?.courses.map((c) => ({
      ...c,
      to: {
        name: 'groupCourse',
        params: { id: props.groupId, courseId: c.id },
        query: route.query,
      },
    }))
  );
  const course = computed(() => details.value?.course);
  const courseDetails = computed(() => ({
    contentsCount: details.value?.contents.length ?? 0,
    completedContentsCount: details.value?.contents.filter((c) => c.learningStatus === 'completed')
      .length,
    isLearning: false,
    isExamOpen: false,
  }));
  const reviewProblemsCount = computed(() => details.value?.course?.reviewProblemCount ?? 0);

  const displayDescription = ref(false);

  const getCourseDetails = useGetCourseDetails();
  async function fetch() {
    loading.value = true;
    const res = await getCourseDetails.execute({ groupId: props.groupId, id: props.courseId });
    loading.value = false;
    if (isFailed(res)) {
      details.value = undefined;
      return;
    }
    details.value = res;
  }

  function init() {
    fetch();
  }
  onMounted(init);
  watch(() => props.courseId, fetch);

  const items = computed(() => {
    if (!details.value || !details.value.course) return [];
    const findExam = (contentId: string) => {
      if (!details.value) return {};
      if (details.value.forTrainee) {
        const exam = details.value.exams.find((item) => item.contentId === contentId);
        if (!exam) return {};
        if (!props.adminMode) return { userExam: exam };
        return { groupExam: { ...exam, id: exam.groupExamId } };
      }
      const exam = details.value.exams.find((item) => item.contentId === contentId);
      if (!exam) return {};
      return { groupExam: exam };
    };
    return details.value.contents.map((c) => ({ ...c, ...findExam(c.id) }));
  });

  return {
    returnQuery,
    menu,
    loading,
    groupName,
    groupRole,
    courses,
    course,
    courseDetails,
    reviewProblemsCount,
    items,
    displayDescription,
    labelCourses: msgs.of('courses'),
    labelBack: msgs.of('backToCourses'),
    labelDescription: msgs.of('description'),
    noDataText: msgs.of('noData'),
  };
}
