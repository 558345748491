















































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import ScheduleTag from '@/base/app/components/atoms/ScheduleTag.vue';
import MentionMarkdown from '@/base/app/components/organisms/MentionMarkdown.vue';

import { useScheduleDialog } from './ScheduleDialogComposable';

export default defineComponent({
  name: 'HomeScheduleDialog',
  components: { ScheduleTag, MentionMarkdown, BaseButtonIcon },
  inheritAttrs: false,
  props: {
    minHeight: { type: String, default: undefined },
  },
  emits: ['click-anchor', 'click-user'],
  setup(_, { emit }: SetupContext) {
    return useScheduleDialog(emit);
  },
});
