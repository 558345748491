

















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { BasePinsItem, PropsBasePins, useBasePins } from './BasePinsComposable';

type Props = PropsBasePins;

export default defineComponent({
  name: 'BaseBasePins',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    items: { type: Array as PropType<BasePinsItem[]>, default: () => [] },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useBasePins(props, emit);
  },
});
