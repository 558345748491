import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useGlobalStore } from '@/base/app/store';

import { PropsGroupFlexCard } from '../molecules/GroupFlexCardComposable';

type HomeGroup = Omit<PropsGroupFlexCard, 'buttonsLimit'>;

export function useTenantForAdmin() {
  const { user, accessibleGroups, getGroupMenusAvailable } = useGlobalStore();

  const groups = computed<HomeGroup[]>(() => {
    if (!user.value) return [];
    const { id: userId, role: userRole } = user.value;
    return accessibleGroups.value
      .map((g) => ({
        id: g.id,
        name: g.name,
        description: g.description,
        groupRole: g.users.find((u) => u.id === userId && !u.removed)?.role,
        adminMode: true,
        role: userRole,
        menusAvailable: getGroupMenusAvailable(true, g.id),
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  });

  const msgs = useMessages({ prefix: 'home.organisms.tenantForAdmin' });
  return { groups, labelNoData: msgs.of('noData') };
}
